import Vue from "vue";
import Vuex from "vuex";
import index from "./modules/index";
import permission from "./modules/permission";
import locip from "./modules/locip";
import notice from "./modules/notice";
import ys from "./modules/ys";
// getter
import getters from "./getters.js";


Vue.use(Vuex);
const store = new Vuex.Store({
    modules: {
        index,
        permission,
        locip,
        notice,
        ys
    },
    getters,
    mutations: {}
});

export default store;
