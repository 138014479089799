export const virtualCurrencyMenuList = [
    {
      icon:'icon-yonghuyanpan',
      title:'用户研判',
      name:"userLook",
      children:[
        {
          title:'地址解析', 
          link:"addressAnalysis",
          name:'addressAnalysis'
        },
        {
          title:'钱包解析', 
          link:"walletAnalysis",
          name:'walletAnalysis'
        },
        {
          title:'钱包监控', 
          link:"walletMonitor",
          name:'walletMonitor'
        },
        {
          title:'地址碰撞', 
          link:"addressCollision",
          name:'addressCollision'
        },
        {
          title:'交易解析', 
          link:"tradeParse",
          name:'tradeParse'
        },
      ]
    },
    {
      icon:'icon-tiaozhengquzheng',
      title:'调证协助',
      name:"certificateAssist",
      children:[
        {
          title:'调证', 
          link:"forensics",
          name:'forensics'
        },
        {
          title:'冻结', 
          link:"freeze",
          name:'freeze'
        },
        {
          title:'解冻', 
          link:"thaw",
          name:'thaw'
        },
      ]
    },
    // {
    //   title:'资料库',
    //   name:"dataBank",
    //   children:[
    //     {
    //       title:'虚拟货币百科', 
    //       link:"/virtualCurrencyWikipedia",
    //       name:'virtualCurrencyWikipedia'
    //     },
    //     {
    //       title:'判决库', 
    //       link:"/sentenceLibrary",
    //       name:'sentenceLibrary'
    //     },
    //     {
    //       title:'政策法规', 
    //       link:"/policiesAndRegulations",
    //       name:'policiesAndRegulations'
    //     },
    //   ]
    // },
    {
      icon:'icon-gerenbiaoqian',
      title:'个人中心',
      name:"personalCenter",
      children:[
        {
          title:'地址标签', 
          link:"addressLabel",
          name:'addressLabel'
        },
      ]
    },
    {
      icon:'icon-gengduofuwu',
      title:'更多服务',
      name:"moreService",
      children:[
        {
          title:'平台服务', 
          link:"platformService",
          name:'platformService'
        },
        {
          title:'定制化服务', 
          link:"customService",
          name:'customService'
        },
      ]
    }
]