
import { _post, _get, _postForm } from './methods'
import qs from "qs";
import Vue from 'vue'
import { getRandomKey } from '@/utils/index'


//  -----------mixin 绑定vue component, 用于loop时取消操作-----------
import ApiBeforeDestroy from '@/mixin/globel-api-before-destroy'
Vue.mixin(ApiBeforeDestroy)  // 此mixin 是为了在BeforeDestroy时能够让轮询中的接口停止
//  ------------------------------End-----------------------------



//  ---------------------可控请求方法-----------------------
export default class $ControllableAPI {
    method = 'get'
    api = ''
    data = ''
    key = ''
    status = 'created'
    isLoop = true           // 默认返回201， 204可以进行loop， 通过前置 disableLoop 可以取消这一项
    componentView = null    // 当前接口绑定的实例App
    _addHeaders = {}        // headers: 这里的headers会增加到已有的headder里面, 且优先级最高

    // 可执行方法
    get = null
    post = null
    postForm = null
    _requestFun = null   // 请求数据

    // 回调方法
    onStatusChange = null
    onUploadProgress = null


    constructor() {
        // 配置可调用的方法
        ['get', 'post', 'postForm'].forEach(funName => this[funName] = (api, data) => {
            this._setRequestInfo(funName, api, data)
            return this;
        })
    }

    // 增加一个query
    addQuery (key, value) {
        if (!this.api) { throw new Error('无法执行[ addQuery ]， 还没有绑定api，请先执行this.[get, post, postForm]') }
        if (this.method === 'get' && this.data && typeof this.data === 'object') {
            this.data[key] = value
        } else {
            let query = qs.parse(this.api.split('?')[1] || '')
            query[key] = value
            this.api = this.api.split('?')[0] + '?' + qs.stringify(query)
        }
    }

    // 增加一个Header
    addHeader(key, value) {
        // FIXED: perid 是 v3 版本单独存在的, 如果不是v3，则这个header不进行携带
        if (key === 'perid' && process.env.VUE_APP_BUILD_VERSION !== "v3") return this
        if (typeof key !== 'string' || typeof value !== 'string') throw new Error('addHeader(key, value)必须是字符串')
        this._addHeaders[key] = value
        return this
    }

    // 监听回调
    /**
     * 目前支持的自定义事件
     * onStatusChange
     */
    onEventList = ['onStatusChange', 'onUploadProgress']
    on (eventName, fun) {
        if (!this.onEventList.includes(eventName)) return console.error('事件构建错误：', '不支持的回调事件 ->', eventName)
        this[eventName] = fun
        console.log()
        return this
    }

    /**
     * @param {*} component 
     * @returns this
     * 
     * 绑定触发的component
     * 默认操作：[绑定后会在页面onload]， 操作者：@/mixin/globel-api-before-destroy
     */
    bindComponent (component) {
        if (!component) return console.error('bindComponent：未传入component实例')
        // 记录下来当前的$ControllableAPI实例
        this.componentView = component
        if (!this.componentView.__controllableAPI_bindApis) this.componentView.__controllableAPI_bindApis = []
        this.componentView.__controllableAPI_bindApis.push(this)
        return this
    }

    /**
     * @returns this
     * 即使返回202， 204也不会进行轮询
     */
    disableLoop () {
        if (Vue.prototype.$AxiosRequestKeys[this.key]) {
            Vue.prototype.$AxiosRequestKeys[this.key]['isLoop'] = false
        }
        this.isLoop = false
        return this
    }
    
    // 开始请求
    async req () {
        return new Promise((resolve, reject) => {
            this.setStatus('activity')
            this._requestFun().then(res => resolve(res)).catch(e => reject(e))
        })
    }

    // 取消请求（同时会清除轮询方法）
    cancel () {
        this.setStatus('canceled')
        if (Vue.prototype.$AxiosRequestKeys[this.key]) {
            Vue.prototype.$AxiosRequestKeys[this.key]['cancel']()
        }
        console.log('request: request canceled')
    }

    /**
     * 获取轮询过程中返回的数据
     */
     getLoopRes(callback){
        this._getLoopRes=callback
        return this
    }

    // 设置请求status
    setStatus (status) {
        this.status = status
        if (Vue.prototype.$AxiosRequestKeys[this.key]) {
            Vue.prototype.$AxiosRequestKeys[this.key]['status'] = status
        }
        // 监听状态变化回调
        if (this.onStatusChange) this.onStatusChange(status)
    }

     // 提前设置请求信息
     _setRequestInfo (method, api, data) {
        this.method = method;
        this.api = api;
        this.data = data;
        // 所有对应的请求数据都可以放在这里，然后在拦截中获取
        this.key = getRandomKey().toString();
        Vue.prototype.$AxiosRequestKeys[this.key] = {
            // api
            '$api': this,
            // 状态
            'isLoop': this.isLoop,
            'status': 'created',
            'loopRequestCount': 0,
            'setStatus': function () {
                this.$api.setStatus(...arguments);
            }
        }
        switch(method) {
            case 'post': {
                this._requestFun = () => _post(this.api, this.data, this.key)
                break
            }
            case 'get': {
                this._requestFun = () => _get(this.api, this.data, this.key)
                break
            }
            case 'postForm': {
                this._requestFun = () => _postForm(this.api, this.data, this.key, this._onUploadProgress.bind(this))
                break
            }
        }

        // let methodName = '_' + method
        // this._requestFun = () => Methods[methodName](this.api, this.data, this.key);
    }
    
    // 上传进度回调
    _onUploadProgress(progressEvent) {
        // console.log('进度,', progressEvent)
        this.onUploadProgress && this.onUploadProgress(progressEvent)
        // if (progressEvent.lengthComputable){
        //     let progress = (( (progressEvent.loaded / progressEvent.total) * 100) | 0);
        //     this.onUploadProgress && this.onUploadProgress({ progress, progressEvent })
        // } else {
        //     this.onUploadProgress && this.onUploadProgress({ progressEvent })
        // }
    }
}


