import Cookies from 'js-cookie'
import dayjs from 'dayjs'
// 格式化数字
const formatNumber = (n) => {
  n = n.toString();
  return n[1] ? n : "0" + n;
};

// 差异时间格式化，到天
export const diffTime = function (st = new Date(), et = new Date()) {
  let startTime = dayjs(st).unix()
  let endTime = dayjs(et).unix()
  if (startTime >= endTime) {
     return {
          day: '00',
          hour: '00',
          min: '00',
          second: '00',
          allSecond: 0
      }
  }
  else {
      let diff = endTime - startTime
      let allSecond = diff
      // 计算出相差天数
      let day = Math.floor(diff / (24 * 60 * 60))
      diff -= day * (24 * 60 * 60)
      // 计算出小时数
      let hour = Math.floor(diff / (60 * 60))
      diff -= hour * (60 * 60)
      // 计算相差分钟数
      let min = Math.floor(diff / 60)
      diff -= min * 60

      // 剩余就是秒数
      let second = diff
      return {
          day: formatNumber(day),
          hour: formatNumber(hour),
          min: formatNumber(min),
          second: formatNumber(second),
          allSecond
      }
  }
}

// 格式化时间
export const formatTime = (date, mode = "s", splitEle = "-") => {
  if (typeof date !== "object" || !date.getFullYear) date = new Date(date);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();
  const second = date.getSeconds();
  let r = [year, month, day].map(formatNumber).join(splitEle);
  if (mode === "s")
    r += " " + [hour, minute, second].map(formatNumber).join(":");
  else if (mode === "min")
    r += " " + [hour, minute].map(formatNumber).join(":");
  return r;
};

// 百度地图level
export const bmapLevelSearch = ({ level, scale } = {}) => {
  const levelMap = {
    18: 50,
    17: 100,
    16: 200,
    15: 500,
    14: 1000,
    13: 2000,
    12: 5000,
    11: 10000,
    10: 20000,
    9: 25000,
    8: 50000,
    7: 100000,
    6: 200000,
    5: 500000,
    4: 1000000,
  };
  // 有比例尺查询需要等级
  if (scale) {
    return parseInt(Object.keys(levelMap).filter((key) => levelMap[key] > scale).sort((a, b) => b - a)[0] || 4);
  } else if (level) {
    return parseInt(levelMap[level]);
  }
};
export const clearQuery = obj =>
  Object.entries(obj)
    .filter(([k, v]) => v !== undefined && v !== '')
    .reduce((prev, [k, v]) => ({ ...prev, [k]: v }), {})


// 实时检索imei 14位加校验位
export const imei14Complete = ($imei) => {
  if (typeof $imei !== "string") $imei = $imei.toString();
  let $sign = 0;
  let $total = 0;
  let $sum1 = 0;
  let $sum2 = 0;
  for (let i = 0;i < $imei.length;i++) {
    let $num = parseInt($imei[i]);
    if (i % 2 === 0) {
      $sum1 += $num;
    } else {
      let $tmp = $num * 2;
      if ($tmp < 10) {
        $sum2 += $tmp;
      } else {
        $sum2 = $sum2 + $tmp + 1 - 10;
      }
    }
  }
  $total = $sum1 + $sum2;
  $sign = (10 - ($total % 10)) % 10;
  return $imei + $sign.toString();
};


// 退出登录
export const logoutRemoveAllCookie = () => {
  // 删除所有cookies
  Object.keys(window.$tj_config['cookies']).forEach(keyName => Cookies.remove(window.$tj_config['cookies'][keyName], { path: "/" }))
}


// 获得随机Key
export const getRandomKey = function () {
  return Math.ceil(new Date().getTime() / (200 + Math.random() * 100)) + Math.ceil(Math.random() * 10000)
}

// 加密转码
export const utf8ToB64 = (obj={}) => {
  let str=JSON.stringify(obj)
  return window.btoa(encodeURIComponent(str));
};

// 解密转码
export const b64ToUtf8 = (str='') => {
  try {
    return JSON.parse(decodeURIComponent(window.atob(str)));
  }  catch (error) {
    console.error(error);
    return null
  }
};

// 全屏展示
export function fullScreen(ele) {
  let element = ele || document.documentElement; //设置后就是我们平时的整个页面全屏效果
  let isFullscreen = false;
  return () => {
    if (isFullscreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
    isFullscreen = !isFullscreen;
  };
}

export function copyText(text,success,error) {
  if (document.execCommand) {
    const input = document.createElement("input");
    document.body.appendChild(input);
    input.setAttribute("value", text);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    success&success()
  } else if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
    success&&success()
  } else {
    error&&error()
  }
}

// 科学计数法转为数字
export function toFullNum(num) {
   //处理非数字
  if(isNaN(num)){return num}
  //处理不需要转换的数字
  var str = ''+num;
  if(!/e/i.test(str)){return num;}
  return Number(num).toFixed(18).replace(/\.?0+$/, "");
}

// 判断是否为空
export function isNotEmpty(value) {
  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.length > 0;
    } else {
      return Object.keys(value).length > 0;
    }
  } else if (typeof value === 'string') {
    return value.trim().length > 0;
  } else if(typeof value === 'number'){
    return true
  }else{
    return value
  }
}