<style>
    .dark-theme .default-con.container {
        color: #fff !important;
    }
</style>
<style lang="less" scoped>
.container {
    color: #333;
    width: 100%;
    height: 100%;
    position: relative;
    .context {
        width: 400px;
        position: absolute;
        text-align: center;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }
    .main-img {
        width: 220px;
    }
    p.info {
        font-size: 13px;
        line-height: 25px;
    }
    p.title {
        font-size: 15px;
        font-weight: bold;
        line-height: 40px;
        letter-spacing: 3px;
        margin-bottom: 7px;
    }
}

</style>
<template>
    <div class="default-con container">
        <div class="context">
            <img src="../../assets/images/default-page.png" class="main-img"/>
            <p class="title">功能维护中</p>
            <p class="info">该功能维护中，暂不可用，具体恢复时间请关注平台通知中心，</p>
            <p class="info">给您带来不便敬请谅解。</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>
