// 定义一个混入对象
const ApiBeforeDestroy = {
    beforeDestroy: function () {
      if (!this.__controllableAPI_bindApis || !this.__controllableAPI_bindApis.length) return
      // 检测判断当前component是否挂载了销毁组件 时 销毁api
      const Apis = this.__controllableAPI_bindApis
      delete this.__controllableAPI_bindApis
      // cancel apis
      Apis.forEach(item => item.cancel())
    }
}

export default ApiBeforeDestroy