
export const parseVariable = {
    // aliyun
    ALIYUN_STATUS: '',
    // 17ce
    SEVENCE_STATUS: '',
    // zhanzhangzhijia
    WEBMASTER_STATUS: '',
    setAliyun(param) {
      this.ALIYUN_STATUS = param
    },
    setSeven(param) {
      this.SEVENCE_STATUS = param
    },
    setWebMaster(param) {
      this.WEBMASTER_STATUS = param
    },
  }
  