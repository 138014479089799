import Cookies from "js-cookie";
import { isArguments } from "lodash";

const globe = {
    // 用户信息
    userInfo: state => {
        if (!state.permission.userInfo) { state.permission.userInfo =  Cookies.getJSON(window.$tj_config["cookies"]["USERINFO"], { path: "/" }) }
        state.permission.userInfo.permission = state.permission.userInfo.permission || {}   // 增加permission字段用作细小权限控制
        return state.permission.userInfo
    },
    // page Loading
    pageLoadingProgressNum: state => state.index.pageLoadingProgressNum,
    logoInfo: state => state.index.logoInfo,  // logo, icon, 标题等东西
    // 全局通知（未读）
    unreadList: state => state.notice.unReadist,
    // 主题类型
    themeType: (state) => {
        let style = state.index.themeType
        if (style) return style
        const themeType = Cookies.get(window.$tj_config["cookies"]["STYLE"], { path: "/" });
        if (themeType) state.index.themeType = themeType
        return themeType
    },
    // 主题名称
    themeName: state => state.index.themeName,
    // 银商接口状态判断
    ysPrimaryInfo: state => state.ys.ysPrimaryInfo,
}

const permission = {
    // v2
    v2_kwPremission:  state => state.permission.v2_kwPremission,
    v2_permissionList: state => state.permission.v2_permissionList,
    v2_menuList: state => state.permission.v2_menuList,
    // v3
    kwPremission:  state => state.permission.kwPremission,
    permissionList: state => state.permission.permissionList,
    menuList: state => state.permission.menuList,
    // appid
    appidAuthData: state => state.permission.appidAuthData,
    fristPage: state => state.permission.fristPage,
}

const other = {
    // locIp
    locipIsShowTraced: state => state.locip.isShowTraced,
    locipTracedData: state => state.locip.locipTracedData,
};

// 导出
const getters = { ...globe, ...permission, ...other };
export default getters
