// *** 银商权限相关 ***

import { getPrimaryInfo } from "@/api/ys";
import { diffTime } from '@/utils/index';

let YseExpireDateDownTimer = null
let getInfoLoading  = false       // 防止重复查询
const Ys = {
    state: {
        ysPrimaryInfo: { isLoaded: false }
    },
    mutations: {
        setYsPrimaryInfo(state, content) {
            // 倒计时置为0
            YseExpireDateDownTimer && clearInterval(YseExpireDateDownTimer)
            // 开始倒计时
            content.surplus_date = diffTime(new Date(), content.expire_date_time || new Date())
            if (content.expire_date_time) {
                YseExpireDateDownTimer = setInterval(() => {
                    content.surplus_date = diffTime(new Date(), content.expire_date_time)
                    // 倒计时结束
                    if (content.surplus_date.allSecond <= 0) {
                        YseExpireDateDownTimer && clearInterval(YseExpireDateDownTimer)
                        content.account_status = 0  // 超期的值置为0
                    }
                }, 1000)
            }
            // result
            state.ysPrimaryInfo = content;
        },
    },
    actions: {
        // 获取银商接口上报过期信息
        async getYsPrimaryInfo({ commit, state }) {
            if (getInfoLoading) return
            getInfoLoading = true
            let { code, content } = await getPrimaryInfo();
            content = content || {}
            content.isLoaded = true
            getInfoLoading = false
            if (code === 200) {
                commit("setYsPrimaryInfo", content);
                return content
            } else {
                commit("setYsPrimaryInfo", { });
                return {}
            }
           
        }
    }
};
export default Ys;