import { Layouts, Components } from "../modules/module";
import { GetDefaultPages } from "./default_router";
// 当前版本
const version = process.env.VUE_APP_BUILD_VERSION
// 所有加载好的单文件组件
let allComponents = null
// v2 根据权限处理路由
const forPermission_v2 = (plist, parents = [], topRouters = []) => {
    let routers = []
    plist.forEach(item => {
        const route = {
            path: (item.link || '').substring(1),
            name: item.name,
            component: allComponents[item.name],
            meta: {
                enabled: item.enabled,
                title: item.title,
                parents,
            }
        }
        // item.target
        item.target = item.target || ''
        // fullpath
        route.meta.fullPath = parents.map(item => item.path).join('/') + '/' + (route.path)
        // 可添加路由
        //   - is_513_show: 是否显示当前此路由，不显示的路由需要加锁
        if (item.is_513_show === false) {
            route.component = allComponents['page403']  // 替换为403页面
            routers.push(route)
        }
        // 没有对应的组件，无法添加对应路由
        else if (!route.component) {
            // ...
        }
        else if (item.target.includes('_top')) { // 顶部打开路由，不适用带菜单的layout
            route.path = route.meta.fullPath
            topRouters.push(route)
        } 
        else {  // 正常添加菜单内路由
            routers.push(route)
        }
        // 递归使用新路由
        if (item.children && item.children.length) {
            routers.push(...forPermission_v2(item.children, [...parents, route], topRouters)['routers'])
        }
    })
    return { routers, topRouters }
}

// v3 根据权限处理路由
const forPermission_v3 = (plist, parents = [], topRouters = []) => {
    let routers = []
    plist.forEach(item => {
        const route = {
            path: (item.link || '').substring(1),
            name: item.name,
            component: allComponents[item.name],
            meta: {
                enabled: item.enabled,
                title: item.title,
                parents,
            }
        }
        // item.target
        item.target = item.target || ''
        // fullpath
        route.meta.fullPath = parents.map(item => item.path).join('/') + '/' + (route.path)
        // 加锁的应用替换403页面
        if (item.is_lock) {
            route.component = allComponents['page403']
            routers.push(route)
        }
        // 添加路由
        else if (route.component && item.target.includes('_top')) { // 顶级路由，全屏打开，不包含菜单 
            topRouters.push(route)
        }
        else if (route.component) { // 普通页面内菜单路由
            routers.push(route)
        }
        // 有子路由继续递归子路由
        if (item.children && item.children.length) {
            routers.push(...forPermission_v3(item.children, [...parents, route], topRouters)['routers'])
        }
    })
    return { routers, topRouters }
}


// 生成动态路由表
const formatRouter = permissions => {
    allComponents = { ...Components, ...GetDefaultPages() }
    // root
    let layout = Layouts[version.toUpperCase()]  // 默认渲染环境判断
    let topLayout = { ...Layouts["top"] }  // top渲染环境判断
    // 格式化数据
    let forPermissionFun = version === 'v2' ? forPermission_v2 : forPermission_v3   // 选择处理函数
    let { routers, topRouters } = forPermissionFun(permissions)    // 开始格式化权限，生成router
    // 普通路由
    layout.children = routers
    // top路由
    topLayout.children = topRouters
    topLayout.name = '_top'
    topLayout.path = ''
    delete topLayout.redirect
    // return
    return { layout, topLayout }
}


// 生成单页面路由插入
const createAppIdSinglePage = (path) => {
    const emptyLayout = { ...Layouts["empty"] }
    allComponents = { ...Components, ...GetDefaultPages() }
    let name = path.substring(1).split('?')[0].replace('/', '-')
    const route = {
        path: path.substring(1),
        name,
        component: allComponents[name],
        meta: {}
    };
    emptyLayout.children = [route]
    return emptyLayout
}


export { formatRouter, createAppIdSinglePage }