import { forPermission } from './forPermission'
import { virtualCurrencyMenuList } from './menu'
import { virtualCurrencyModel } from './module'

 
// 应该只有一个入口路由
const Components = {
    'block-chain': () => import(/* webpackChunkName: "block-chain" */ "@/pages/block/entry.vue"),
}

// 需要添加的静态路由
const staticRoutes = [
    {
        path:'/block/fullScreen',
        component: () => import(/* webpackChunkName: "block-chain-fullScreen" */ "@/pages/block/fullScreen/index.vue"),
        parent: 'block-chain',
    },
    {
        path: '',
        component: () => import(/* webpackChunkName: "block-chain-layout" */ "@/pages/block/layout/index.vue"),
        children: forPermission(virtualCurrencyMenuList, virtualCurrencyModel),
        parent: 'block-chain',
    }
]

export {
    Components,
    staticRoutes
}



// export const dynamicRoute = [
//     {
//       path:'/block',
//       component: () => import(/* webpackChunkName: "layout" */ "@/pages/block/layout/index.vue"),
//       redirect:'/block/fullScreen',
//       children: forPermission(virtualCurrencyMenuList, virtualCurrencyModel)
//     },
//     {
//       path:'/block/fullScreen',
//       component: () => import(/* webpackChunkName: "fullScreen" */ "@/pages/block/fullScreen/index.vue"),
//       // children: []
//     },
// ]

// export default {
//     path: '/block',
//     component: () => import(/* webpackChunkName: "block" */ "@/pages/layout/empty.vue"),
//     children: dynamicRoute
// }