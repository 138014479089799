// 递归获得路由表
export const forPermission = (plist, components,parent=null) => {
    let routers = []
    plist.forEach(item => {
        const route = {
            path: (item.link || ''),
            name: item.name,
            component: components[item.name],
            meta: {
                enabled: item.enabled,
                title: item.title,
                parent: parent
            }
        }
        if (route.component) {
            routers.push(route)
        }
        if (item.children && item.children.length) {
            routers.push(...forPermission(item.children, components,item))
        }
    })
    return routers
}