import {getConfig} from "@/config/index.config.js"

// 设置标题和
export const setPageTitleAndIcon = (value) => {
  const Config = getConfig()
    if (value) {
      document.title = value.title.content_v2;
      document.getElementById("x-icon").href = value?.ico || value?.logo?.url || Config.defalutLogo;
    } else {
      document.title = Config.defaultTitle;
      document.getElementById("x-icon").href = Config.defaultIcon;
    }
}

// 添加csrf Meta
export const addCsrfMeta = (token) => {
  const CsrfMeta = document.querySelector('meta[name="csrf-token"]')
  if (CsrfMeta) return CsrfMeta.content = token
  const meta = document.createElement("meta");
  meta.charset = "utf-8";
  meta.name = "csrf-token";
  meta.content = token;
  document.getElementsByTagName("head")[0].appendChild(meta);
};

// 加载单个js组件
export const createSctipt = function(src, callbackName) {
  return new Promise((resolve, reject) => {
      let script = document.createElement("script")
      script.src = src
      if (callbackName) window[callbackName] = () =>  resolve(...arguments)
      else script.onload = () => {
        console.log('加载JS完成', src)
        resolve()
      }
      script.onerror = (e) => reject(e)
      document.head.appendChild(script)
      console.log('加载JS', src)
  })
}

// 加载单个css组件
export const createCss = function(src) {
  return new Promise((resolve, reject) => {
      let link = document.createElement("link");
      link.href = src;
      link.rel = "stylesheet";
      document.head.appendChild(link);
      resolve()
  })
}
