import store from '../store';
import Cookies from "js-cookie";

// 当前主题样式
let isDisableSetTheme = false
// 引入主题
const themes = {
    dark: {
        name: '深色主题',
        type: 'dark',
        key: 'dark',
        isShow: true,
        link: require('./dark.less')
    },
    light: {
        name: '亮色主题',
        type: 'light',
        key: 'light',
        isShow: true,
        link: require('./light.less'),
    },

    pro: {
        name: 'pro',
        type: 'light',
        key: 'pro',
        isShow: false,
        link: require('./pro.less'),
    },
    tm: {
        name: '天幕',
        type: 'light',
        key: 'tm',
        isShow: false,
        link: require('./tm.less'),
    }
}



// 主题link ID
const themeId = "head-theme-link"


// 全局设置、删除主题的方法
window.$removeTheme = function () {
    if (isDisableSetTheme) return;
    const themeLink = document.getElementById(themeId)
    if (themeLink) { themeLink.remove() }
}

// 设置主题的方法
window.$setTheme = function (scopeName = 'dark') {
    if (isDisableSetTheme) return;  // 如果被禁用主题切换功能，就不用继续
    // 存Cookies，当前theme
    Cookies.set(window.$tj_config["cookies"]["STYLE"], themes[scopeName].type, { path: "/" })
    // 同时存vuex一份
    store.commit("setThemeType", themes[scopeName].type);
    store.commit("setThemeName", scopeName);
    // 删除已有的theme
    window.$removeTheme()

    // 设置独有类名
    document.body.className = scopeName + '-theme'

    // 新建一个link插入
    let styleLink = document.createElement("link");
    styleLink.type = "text/css";
    styleLink.rel = "stylesheet";
    styleLink.id = themeId;
    styleLink.href = themes[scopeName].link
    document.head.append(styleLink);
}

window.$setThemeDisable = function () {
    isDisableSetTheme = true
}

export default themes