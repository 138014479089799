// 文档 https://code.yhzm.net/gweb/insight/-/wikis/PaymentsOfXZW

import { $get, $post, $postForm, $ControllableAPI } from "@/request/index";

// 获取主账号信息
export function getPrimaryInfo(data) {
    return $get("/analysis/payments/getPrimaryInfo",data);
}

// 发送主账号手机验证码
export function sendPrimaryCode(data) {
  return $post("/analysis/payments/sendPrimaryCode",data);
}

// 校验主账号手机验证码
export function checkPrimaryCode(data) {
    return $post("/analysis/payments/checkPrimaryCode",data);
}

// 获取授权基本信息
export function getUserAuthInfo(data) {
    return $post("/analysis/payments/getUserAuthInfo",data);
}

// 获取有问题的批次文件
export function getUploadeProblemList(data) {
    return $get("/analysis/payments/report/getProblemList", data);
}

// 获取当前用户的有问题的批次文件
export function getUserUploadeProblemList(data) {
    return $get("/analysis/payments/report/getUserProblemList", data);
}

// 上传用户错误文件
export function uploadProblem(params) {
    return new $ControllableAPI().postForm('/analysis/payments/report/uploadProblem', params)
}

// 获取案件类型
export function getCaseTypeList(data) {
    return $get("/analysis/payments/getCaseTypeList",data);
}

// 获取协查来源列表
export function getCheckSourceList(data) {
    return $get("/analysis/payments/getCheckSourceList",data);
}

// 上传协议函文件
export const uploadPactFileApi = "/analysis/payments/uploadPactFile";

// 批量上传协议函文件
export const uploadBatchPactFile = "/analysis/payments/uploadBatchPactFile";

// 保存主账号授权
export function savePrimaryAuth(data) {
    return $post("/analysis/payments/savePrimaryAuth",data);
}

// 获取上报列表
export function getReportList(data) {
    return $get("/analysis/payments/getReportList", data);
}

// 下载选中的列表材料
export function downloadMaterial(data) {
    return $post("/analysis/payments/downloadMaterial",data);
}

// 重置所有已下载上报状态
export function resetReportStatus(data) {
    return $post("/analysis/payments/resetReportStatus", data);
}


// 批量上传检索条件文件
export const uploadBatchIdFile = "/analysis/payments/uploadBatchIdFile";
// 批量上传公安机关协助查询盖章材料文件
export const uploadBatchPoliceFile = "/analysis/payments/uploadBatchPoliceFile";
// 保存材料上报
export function saveMaterial(data) {
    return $post("/analysis/payments/saveMaterial", data);
}

// 删除上报条件
export function delReportCond(data){
    return $get('/analysis/payment/delete',data)
  }
