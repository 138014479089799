// 页面内使用的cookie name
const Cookies = {
    "LOGINKEY": "TJ-V2-IS-LOGIN_AUTH",
    "USERINFO": "TJ-V2-USERINFO",
    "CSRF_TOKEN": "TJ-V2-CSRF_TOKEN",
    "APPID_USERINFO": "TJ-V2-APPID_USERINFO",
    "VERSION": "TJ-V2-VERSION",
    "ISHAVECHECK": "IS-HAVE-CHECK",
    'STYLE':"TJ-V2-THEME"
}

export default Cookies