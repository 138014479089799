import { unreadMessage } from "@/api/platform";

const Notice = {
    state: {
        unReadist: []
    },
    mutations: {
        setUnreadList(state, data) {
            state.unReadist = data;
        },
    },
    actions: {
        // 获取未读消息
        async setUnreadMessage({ commit, state }) {
            let { code, content } = await unreadMessage();
            if (code === 200) {
                commit("setUnreadList", content);
            } else {
                commit("setUnreadList", []);
            }
        },

    }
};
export default Notice;