<style lang="less" scoped>
.light-theme .default_page.box_content {
    background: #fff;
}
</style>

<template>
    <div :style="{width: '100%;', height: `calc(100vh - ${navHeight}px)`}" class="default_page box_content">
      <we-default />
    </div>
</template>

<script>
import weDefault from "@/pages/util_view/default.vue"
export default {
    components: {
        weDefault
    },
    data() {
        return {
            navHeight: 230 
        }
    },
    created() {
        if (process.env.VUE_APP_BUILD_VERSION === "v3") {
            this.navHeight = 230
        } else if (process.env.VUE_APP_BUILD_VERSION === "v2") {
            this.navHeight = 165
        }
    }
}
</script>
