export const virtualCurrencyModel = {
    addressAnalysis:() => import(/* webpackChunkName: "addressAnalysis" */ "@/pages/block/addressAnalysis/index.vue"),
    addressCollision:() => import(/* webpackChunkName: "addressAnalysis" */ "@/pages/block/addressCollision/index.vue"),
    walletAnalysis:() => import(/* webpackChunkName: "walletAnalysis" */ "@/pages/block/wallet/analysis.vue"),
    walletMonitor:() => import(/* webpackChunkName: "walletMonitor" */ "@/pages/block/wallet/monitor.vue"),
    tradeParse:() => import(/* webpackChunkName: "tradeParse" */ "@/pages/block/tradeParse/index.vue"),
    forensics:() => import(/* webpackChunkName: "forensics" */ "@/pages/block/forensics/index.vue"),
    freeze:() => import(/* webpackChunkName: "freeze" */ "@/pages/block/freezeAndThaw/freeze.vue"),
    thaw:() => import(/* webpackChunkName: "thaw" */ "@/pages/block/freezeAndThaw/thaw.vue"),
    // sentenceLibrary:() => import(/* webpackChunkName: "sentenceLibrary" */ "@/pages/block/util_view/default.vue"),
    // virtualCurrencyWikipedia:() => import(/* webpackChunkName: "virtualCurrencyWikipedia" */ "@/pages/block/util_view/default.vue"),
    // policiesAndRegulations:() => import(/* webpackChunkName: "policiesAndRegulations" */ "@/pages/block/util_view/default.vue"),
    addressLabel:() => import(/* webpackChunkName: "addressLabel" */ "@/pages/block/addressLabel/index.vue"),
    platformService:() => import(/* webpackChunkName: "platformService" */ "@/pages/block/moreService/platformService.vue"),
    customService:() => import(/* webpackChunkName: "customService" */ "@/pages/block/moreService/customService.vue"),
}