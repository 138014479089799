import defaultPage from '@/pages/util_view/default_page';
import Cookies from "js-cookie";
import { Components } from "../modules/module";

// 白名单手机号可以看
const RouteDefaultPageWhiteList = ['18810556322']
// const DefaultPages = ["analysis-portray", "analysis-four", "analysis-transfer", "analysis-consume", "analysis-payments"]
const DefaultPages = []

// 判断返回
export const checkShowDefaultPage = (a = true, b = false) => {
    let userInfo = Cookies.getJSON(window.$tj_config["cookies"]["USERINFO"]);
    if (!userInfo || !RouteDefaultPageWhiteList.includes(userInfo.login_name)) return a;
    return b;
}

// 缺省设置
export const GetDefaultPages = () => {
    return DefaultPages.reduce((obj, name) => {
        obj[name] = checkShowDefaultPage(defaultPage, Components[name])
        return obj
    }, {})
}