import { getLogo } from '@/api/login'
import { setPageTitleAndIcon } from '@/utils/document'
import Config from '@/config/index.config.js'
import debounce from 'lodash/debounce'

// 自定义外置参数
let pageLoadProgressTimer = null

const indexStore = {
    state: {
        userInfo: null, // 个人信息
        globeLoading: false, // 页面加载全局loading
        pageLoadingProgressNum: 0,
        logoInfo: null, // 系统基础信息，如logo
        themeName: '', // 主题名称
        themeType: '' // 主题色系类型
    },
    mutations: {
        // 设置全局loading, 页面首次加载的时候会多次变化，需要加防抖
        setGlobeLoading: debounce((state, val) => {
            document.getElementById('main_loading').style.display = val ? 'block' : 'none'
            state.globeLoading = val
        }, 50),
        // 设置页面内加载进度条的值， 瞬间加载完成的就不用了，所以加防抖
        setPageLoadingNum: debounce((state, num) => {
            state.pageLoadingProgressNum = num
        }, 50),
        setLogoInfo(state, val) {
            state.logoInfo = val
        },
        setThemeType(state, type) {
            state.themeType = type
        },
        setThemeName(state, name) {
            state.themeName = name
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        }
    },
    actions: {
        // 设置页面加载 loading 进度条
        setPageProgressLoading({ commit, state }, isLoading) {
            if (pageLoadProgressTimer) {
                clearInterval(pageLoadProgressTimer)
            }
            if (isLoading) {
                commit('setPageLoadingNum', 0)
                let count = 40
                const addCount = () => {
                    commit('setPageLoadingNum', state.pageLoadingProgressNum + count)
                    count = count / 2 > 1 ? count / 2 : 1
                    if (state.pageLoadingProgressNum >= 90) count = 0
                }
                addCount()
                pageLoadProgressTimer = setInterval(addCount, 500)
            } else {
                commit('setPageLoadingNum', 100)
            }
        },

        // 获得logo, 标题等信息
        async getLogoInfo({ state, commit }) {
            // 已经有了内容，直接resolve
            if (state.logoInfo) return true
            // 重新获取
            let content = null
            const isRequestLogoInfo = 'isRequestLogoInfo'
            const tjLogoInfo = 'tj_logoInfo'
            try {
                // 优先级: 1 （写死在代码里的）
                if (Config.projectInfo) {
                    content = Config.projectInfo
                }

                // 优先级: 2 （sessionStorage保存的）
                else if (sessionStorage.getItem(isRequestLogoInfo)) {
                    content = sessionStorage.getItem(tjLogoInfo) || null // 因为content可能为空，所以单独用一个字段标识请求过了
                    if (content) content = JSON.parse(content)
                }

                // 优先级: 3 （去后端读取）
                else {
                    let res = await getLogo(document.domain)
                    // let res = await getLogo('ruijin.zmeng123.cn' || document.domain)
                    // let res = await getLogo('szzn.zmeng123.cn' || document.domain)
                    if (res.code === 200) {
                        sessionStorage.setItem(isRequestLogoInfo, 'true') // 标记请求过
                    }
                    if (res.content) {
                        content = res.content
                        sessionStorage.setItem(tjLogoInfo, JSON.stringify(content)) // 添加请求内容
                    }
                }
                // 设置标题和icon
                setPageTitleAndIcon(content)
                commit('setLogoInfo', content)
            } catch (e) {
                console.error(e, '请求LOGO错误, 直接返回，等待第二次请求')
                return false
            }
        }
    }
}

export default indexStore
