import { getPermissionV2, getPermissionV3, appidIdentify } from "@/api/login";
// import { staticLayoutChildPages } from '@/router/model'
import Cookies from "js-cookie";

const permissionStore = {
    state: {
        // v3风格
        permissionList: [],
        menuList: [],
        kwPremission: {},

        // v2风格
        v2_permissionList: [],
        v2_menuList: [],
        v2_kwPremission: {},

        // APPID 获取的数据
        appidAuthData: null,
        fristPage: "",
    },

    mutations: {
        // 根目录跳转首页
        setFristPage(state, pagePath = '') {
            state.fristPage = pagePath
        },
        
        // v2 初始化和保存权限
        setPermission_v2(state, data = []) {
            state.v2_permissionList = data
            state.v2_menuList = [...data]
            let kwPremission = {}
            const deepGet = (p = []) => {
                p.forEach(item => {
                    kwPremission[item.name] = { ...item }
                    if (item.children && item.children.length) { deepGet(item.children) }
                })
            }
            deepGet(data)
            state.v2_kwPremission = kwPremission
        },

        // v3 初始化和保存权限
        setPermission_v3(state, data = []) {
            state.permissionList = data
            state.menuList = [...data]
            let kwPremission = {}
            const deepGet = (p = []) => {
                p.forEach(item => {
                    kwPremission[item.name] = { ...item }
                    if (item.children && item.children.length) { deepGet(item.children) }
                })
            }
            deepGet(data)
            state.kwPremission = kwPremission
        },

        setAppidAuth (state, data) {
            state.appidAuthData = data
        }
    },
    
    actions: {
        // v3请求权限
        async getPermission ({ commit }) {
            const version = process.env.VUE_APP_BUILD_VERSION

            // 当前版本
            console.log(version, '：当前账号登录的版本, v2 -- 天迹, v3 -- 天安')

            // 请求权限接口并存起来
            let permissionFun = null

            if (version === 'v2') {
                permissionFun = getPermissionV2
            }
            else if (version === 'v3') {
                permissionFun = getPermissionV3
            }

            // action
            let res = await permissionFun();
            if (res.code !== 200) {
                console.log("不 == 200")
                Cookies.remove(window.$tj_config["cookies"]["LOGINKEY"], { path: "/" });
                return window.location = window.$PublicPath;
            }
            // setPermission_v2
            // or
            // setPermission_v3
            // commit(`setPermission_${version}`, res.content.concat(staticLayoutChildPages));
            commit(`setPermission_${version}`, res.content);
        },

        // appid
        async appidCheckAuth ({ commit }, data) {
            let res = await appidIdentify(data);
            if (res && res.code === 200) {
                commit('setAppidAuth', data)
                // 写入cookies
                sessionStorage.setItem(window.$tj_config["cookies"]["APPID_USERINFO"], JSON.stringify(res.content))
            }
            return res.code === 200
        }
    }
};
export default permissionStore;