
// 公用组件
const publicComponents = require('./public/index')
// 等待引入的版本组件
let includeComponents = null
// 根据编译条件，找到应该引入的组件
if (process.env.VUE_APP_BUILD_VERSION === 'v3') {
    includeComponents = require('./tianan/index') || {}
}
else if (process.env.VUE_APP_BUILD_VERSION === 'v2') {
    includeComponents = require('./tianji/index') || {}
}

// 额外增加的组件
const blockComponents = require('./block/index')

// components
const Components = {
    ...publicComponents.Components,
    ...includeComponents.Components,
    ...blockComponents.Components
}

// 静态路由, 写在此处可访问 , parent代表想要插入的父级name，默认为空，即是app.router下
const staticRoutes = [
    ...publicComponents.staticRoutes,
    ...includeComponents.staticRoutes,
    ...blockComponents.staticRoutes
]


// layouts
const Layouts = {
    V2: {
        path: "/",
        name: "Layout",
        redirect: "",
        component: () => import(/* webpackChunkName: "Layout" */ "@/pages/layout/index"),
        children: []
    },
    V3: {
        path: "/",
        name: "Layout-v3",
        redirect: "",
        component: () => import(/* webpackChunkName: "Layout-v3" */ "@/pages/layout/v3"),
        children: []
    },
    empty: {
        path: "/",
        name: "Layout-empty",
        redirect: "",
        component: () => import(/* webpackChunkName: "emptyLayout" */ "@/pages/layout/empty"),
        children: []
    },
    top: {
        path: "/",
        name: "Layout-top",
        redirect: "",
        component: () => import(/* webpackChunkName: "topLayout" */ "@/pages/layout/top"),
        children: []
    },
    
}



// 导出
export {
    Components,
    staticRoutes,
    Layouts
}