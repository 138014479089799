const locIpStore = {
    state: {
        isShowTraced: false,
        locipTracedData: {}
    },
    mutations: {
        locip_cleanTraced(state, val) {
            state.isShowTraced = false;
        },
        locip_setTracedData(state, data) {
            state.isShowTraced = true;
            state.locipTracedData = data
        }
    }
};
export default locIpStore;
  