import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import Cookies from 'js-cookie';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

import { addCsrfMeta } from './utils/document';

// 全局使用到的配置
import dayjs from "dayjs"
window.dayjs = dayjs

// 错误收集上报
import "@/utils/errorUpload.js";
// 图片预览全局配置
import VDPhoto from 'vue-dark-photo'
// 引入vue-dark-photo 样式
import 'vue-dark-photo/lib/vue-dark-photo.css'
Vue.use(VDPhoto)


// 导入全局config
import {getConfig} from '@/config/index.config.js'
const Config = getConfig()
Vue.prototype.$config = Config


// 其他window变量配置
window.$PublicPath = process.env.BASE_URL;
window.$isProduction = process.env.NODE_ENV === "production";
window.$tj_config = {
    cookies: Config.cookies
};


// 组件导入都在这个文件夹里
import "./antv/index"


// 自定义错误 用于抛出
window.$customError = function (name, message) {
    this.name = name;
    this.message = message || "Default Message";
    this.stack = new Error().stack;
};
window.$customError.prototype = Object.create(Error.prototype);
window.$customError.prototype.constructor = window.$customError;


// 添加页头csrf
let csrfToken = Cookies.get(window.$tj_config["cookies"]["CSRF_TOKEN"]);
if (csrfToken) addCsrfMeta(csrfToken);


// 设置回到页头
window.$ScrollToTop = (y = 0, x = 0) => window.scrollTo(x, y);

// global style
import "./styles/reset.less";
import "./styles/app.less";
import "@/assets/menu_iconfont/iconfont.css";
import "@/assets/iconfont/iconfont.css";



// 引入主题切换
import "./themes/index"
// 如果是pro, 直接使用pro的主题色 且不许换
if (Config.isProHost) {
    window.$setTheme('pro')
    window.$setThemeDisable()
} else if (Config.isTmHost){
  window.$setTheme('tm')
  window.$setThemeDisable()
}


// 是否为演示账号数据
window.$isDemoUser = (Cookies.getJSON(window.$tj_config["cookies"]["USERINFO"]) || {}).is_demo_user || false;

// 格式化金额
Vue.filter('formatMoney',(val)=>{
    if(val==''||isNaN(val)){
      return val
    }else{
      return Math.floor(val*100)/100
    }
  })

// 挂载
Vue.config.productionTip = false;
new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");
