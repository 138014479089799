// 基础请求组件


import { $http, baseURL } from './index'
import axios from "axios";
import qs from "qs";
import Vue from 'vue'



// 提前定义Vue下的挂载
Vue.prototype.$AxiosRequestKeys = {}


// 取消请求的方法： CANCEL_TOKEN
const CANCEL_TOKEN = axios.CancelToken;
const createCancelTokenBindKey = (requestKey) => {
    return new CANCEL_TOKEN(cancelFun => { //强行中断请求要用到的，记录请求信息
        Vue.prototype.$AxiosRequestKeys[requestKey]['cancel'] = cancelFun;
    })
}


// post from 请求
export function _postForm (url, data, requestKey = '', _onUploadProgress = undefined) {
    return $http({
        url,
        method: "POST",
        headers: {
            "content-type": "application/x-www-form-urlencoded",
            'Tj-Request-Key': requestKey
        },
        data,
        onUploadProgress: _onUploadProgress,
        cancelToken: requestKey ? createCancelTokenBindKey(requestKey) : undefined,
    });
}


// post json 请求
export function _post (url, data, requestKey = '') {
    return $http({
        url,
        method: "POST",
        headers: { "content-type": "application/json;charset=UTF-8", 'Tj-Request-Key': requestKey },
        data,
        cancelToken: requestKey ? createCancelTokenBindKey(requestKey) : undefined,
    });
}


// get 请求
export function _get (url, params, requestKey = '') {
    const request = $http({
        url: params ? url + '?' + qs.stringify(params) : url,
        method: "get",
        cancelToken: requestKey ? createCancelTokenBindKey(requestKey) : undefined,
        headers: {
            'Tj-Request-Key': requestKey
        }
    });
    return request
}
