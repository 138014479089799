import Vue from "vue";
import { parseVariable } from '@/utils/globalVariable.js'


// ant-design-vue
import {
    Progress,
    Empty,
    Button,
    message,
    Upload,
    FormModel,
    Modal,
    Pagination,
    Table,
    Icon,
    Select,
    Input,
    ConfigProvider,
    Layout,
    DatePicker,
    Tooltip,
    Radio,
    Spin,
    Tabs,
    Alert,
    Tree,
    Tag,
    Popover,
    Form,
    notification,
    Popconfirm,
    Switch,
    Checkbox,
    InputNumber,
    Divider,
    PageHeader,
    Descriptions,
    Dropdown,
    Menu,
    Badge,
    List,
    TreeSelect,
    Row,
    Col,
    Carousel,
    Steps,
    Cascader,
    Collapse
} from "ant-design-vue";

Vue.use(Carousel);
Vue.use(Row);
Vue.use(Col);
Vue.use(Descriptions);
Vue.use(PageHeader);
Vue.use(InputNumber);
Vue.use(Checkbox);
Vue.use(Button);
Vue.use(Upload);
Vue.use(FormModel);
Vue.use(Modal);
Vue.use(Pagination);
Vue.use(Table);
Vue.use(Icon);
Vue.use(Select);
Vue.use(Input);
Vue.use(ConfigProvider);
Vue.use(Layout);
Vue.use(DatePicker);
Vue.use(Tooltip);
Vue.use(Radio);
Vue.use(Spin);
Vue.use(Tabs);
Vue.use(Alert);
Vue.use(Tree);
Vue.use(Tag);
Vue.use(Popover);
Vue.use(Form);
Vue.use(Popconfirm);
Vue.use(Empty);
Vue.use(Switch);
Vue.use(Divider);
Vue.use(Progress);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Badge);
Vue.use(List);
Vue.use(TreeSelect);
Vue.use(Steps);
Vue.use(Cascader);
Vue.use(Collapse);


Vue.prototype.$message = message;
// 重新定义error, 不提示 NO_SHOW_THE_MESSAGE
Vue.prototype.$message.error_info = Vue.prototype.$message.error 
Vue.prototype.$message.error  = function(message) {
    if (message === 'NO_SHOW_THE_MESSAGE') { return }
    Vue.prototype.$message.error_info(...arguments)
}

Vue.prototype.$notification = notification;
Vue.prototype.$Modal = Modal;
Vue.prototype.$confirm = Modal.confirm;
Vue.prototype.$success = Modal.success;


Vue.prototype.$parseVariable = parseVariable
