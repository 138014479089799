import { $get, $post, $postForm } from "@/request/index";

//记录
export function manager() {
  return $get("/platform/manager");
}
export function managerEdit(data) {
  return $post("/platform/manager", data);
}
export function notice(data) {
  return $get("/platform/notice", data);
}





// 修改密码
export function chpasswd(data) {
  return $post("/platform/chpasswd", data);
}

// 开启二次验证
export function switchTwicePwd(data) {
  return $post("/authTwice", data);
}

// 获取切换版本url
export function getCheckVersion() {
  return $get("/platform/switchV");
}

//平台通知
export function noticeList(data) {
  return $get("/platform/notice/list", data);
}
//消息中心
export function noticeAll(data) {
  return $get("/platform/all/notice/list",data);
}
export function readMessage(data) {
  return $get("/platform/read/message", data);
}
export function unreadMessage() {
  return $get("/platform/unread/notice/list");
}
// --------标签管理---------
export function smsLabelList(data) {
  return $post("/analysis/sms/label/list", data);
}
export function addOrEditSmsLabelList(data) {
  return $post("/analysis/sms/label/save", data);
}
export function delSmsLabel(data) {
  return $post("/analysis/sms/label/del", data);
}
export function uploadFile(data) {
  return $postForm("/platform/upload/file",data);
}


// ===========ys子账号权限管理===============
export function getChildAuth(data){
  return $get("/platform/payments/config",data)
}

export function setChildAuth(data){
  return $post("/platform/payments/config/set",data)
}

